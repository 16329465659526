import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './core/auth-guard.service';

declare var config: any;
var routes: Routes = [];

if (config.viewpltf && config.viewpltf == 'mobile') {
  var routes: Routes = [
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      loadChildren: () => import('./views/home-single/home-single.module').then(m => m.HomeSinglePageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'login',
      loadChildren: () => import('./views/login/login.module').then(m => m.LoginPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'register',
      loadChildren: () => import('./views/register/register.module').then(m => m.RegisterPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: true
      }
    },
    {
      path: 'modals/popup',
      loadChildren: () => import('./views/modals/popup/popup.module').then(m => m.PopupPageModule)
    },

    
    {
      path: 'modals/change-dilivary-method-food-dlow',
      loadChildren: () => import('./component/food-flow-change-delivery/food-flow-change-delivery.module').then(m => m.FoodFlowChangeDeliveryModule)
    },

    
    {
      path: 'modals/change-dilivary-method-food-dlow-popup',
      loadChildren: () => import('./views/modals/food-flow-change-delivery-popup/food-flow-change-delivery-popup.module').then(m => m.FoodFlowChangeDeliveryPopupPageModule)
    },

    {
      path: 'modals/cart',
      loadChildren: () => import('./views/modals/cart/cart.module').then(m => m.CartPageModule)
    },
    {
      path: 'modals/toppings',
      loadChildren: () => import('./views/modals/toppings/toppings.module').then(m => m.ToppingsPageModule)
    },
    {
      path: 'modals/cross-selling',
      loadChildren: () => import('./views/modals/cross-selling/cross-selling.module').then(m => m.CrossSellingPageModule)
    },
    {
      path: 'modals/table-reservation',
      loadChildren: () => import('./views/modals/table-reservation/table-reservation.module').then(m => m.TableReservationPageModule)
    },
    {
      path: 'modals/select-order-type',
      loadChildren: () => import('./views/modals/select-order-type/select-order-type.module').then(m => m.SelectOrderTypePageModule)
    },
    {
      path: 'restaurant/:slug',
      loadChildren: () => import('./views/ordering-main2/ordering-main2.module').then(m => m.OrderingMain2PageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },

    {
      path: 'branches-map',
      loadChildren: () => import('./views/branches-map/branches-map.module').then(m => m.BranchesMapPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'pages/:slug',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesPageModule)
    },
    {
      path: 'model/tour',
      loadChildren: () => import('./views/modals/tour/tour.module').then(m => m.TourPageModule)
    },
    {
      path: 'restaurant-list',
      loadChildren: () => import('./views/restaurant-list/restaurant-list.module').then(m => m.RestaurantListPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'checkout',
      loadChildren: () => import('./views/checkout/checkout.module').then(m => m.CheckoutPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'alert',
      loadChildren: () => import('./views/modals/alert/alert.module').then(m => m.AlertPageModule)
    },
    {
      path: 'profile',
      loadChildren: () => import('./views/profile-update/profile-update.module').then(m => m.ProfileUpdatePageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    }, {
      path: 'forgot-password',
      loadChildren: () => import('./views/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'my-orders',
      loadChildren: () => import('./views/past-order/past-order.module').then(m => m.PastOrderPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    }, {
      path: 'branches-map/:slug/review',
      loadChildren: () => import('./views/restaurant-review/restaurant-review.module').then(m => m.RestaurantReviewPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'order-summery/:unique',
      loadChildren: () => import('./views/order-summery/order-summery.module').then(m => m.OrderSummeryPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'imprint/:slug',
      loadChildren: () => import('./views/imprint/imprint.module').then(m => m.ImprintPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'open-hours/:restaurant_id',
      loadChildren: () => import('./views/open-hours/open-hours.module').then(m => m.OpenHoursPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'register-via',
      loadChildren: () => import('./views/register-via/register-via.module').then(m => m.RegisterViaPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'additives-and-substances',
      loadChildren: () => import('./views/additives-and-substances/additives-and-substances.module').then(m => m.AdditivesAndSubstancesPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'inbox',
      loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: true,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'notification',
      loadChildren: () => import('./views/notification-content/notification-content.module').then(m => m.NotificationContentPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: true,
        sideMenu: true,
        footerMenu: true
      }
    },
    {
      path: 'update-required',
      loadChildren: () => import('./views/app-update/app-update.module').then(m => m.AppUpdatePageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: false
      }
    },
    {
      path: 'imprint-single',
      loadChildren: () => import('./component/imprintrestaurent/imprintrestaurent.module').then(m => m.ImprintrestaurentPageModule),
    }
  ];
}

if (config.viewpltf && config.viewpltf == 'web') {
  var routes: Routes = [
    {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full'
    },
    {
      path: 'alert',
      loadChildren: () => import('./views/modals/alert/alert.module').then(m => m.AlertPageModule)
    },
    {
      path: 'home',
      loadChildren: () => import('./views/web-views/weblanding/weblanding.module').then(m => m.WeblandingPageModule),
      canActivate: [AuthGuardService],
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: false
      }
    },
    {
      path: 'menu/:slug',
      loadChildren: () => import('./views/web-views/web-menu/web-menu.module').then(m => m.WebMenuPageModule),
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: true
      }
    },
    {
      path: 'restaurants-list/:ref',
      loadChildren: () => import('./views/web-views/web-restaurants-list/web-restaurants-list.module').then(m => m.WebRestaurantsListPageModule),
      data: {
        needAuth: false,
        sideMenu: false,
        footerMenu: true
      },
      
    },
    {
      path: 'imprint-single',
      loadChildren: () => import('./component/imprintrestaurent/imprintrestaurent.module').then(m => m.ImprintrestaurentPageModule),
    },
    {
      path: 'modals/change-dilivary-method-food-dlow',
      loadChildren: () => import('./component/food-flow-change-delivery/food-flow-change-delivery.module').then(m => m.FoodFlowChangeDeliveryModule)
    },

    
    {
      path: 'modals/change-dilivary-method-food-dlow-popup',
      loadChildren: () => import('./views/modals/food-flow-change-delivery-popup/food-flow-change-delivery-popup.module').then(m => m.FoodFlowChangeDeliveryPopupPageModule)
    },

  ];
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
